import React, { Component } from 'react';
import './App.css';
import btn_icon_625285 from './images/btn_icon_625285.png';
import img_state0_elIconaStudioDesign900480 from './images/CompPageFotografia_state0_elIconaStudioDesign757992_757992.png';
import img_state0_elIconaFotocameta972705 from './images/CompPageStudioDesign_state0_elIconaFotocameta835478_835478.png';
import img_state0_elIconaInstagram572998 from './images/CompPageStudioDesign_state0_elIconaInstagram554514_554514.png';
import img_state0_elIconaWhatsapp1016741 from './images/CompPageStudioDesign_state0_elIconaWhatsapp237991_237991.png';
import img_state1_elIconaStudioDesign900480 from './images/CompPageFotografia_state0_elIconaStudioDesign757992_757992.png';
import img_state1_elIconaFotocameta972705 from './images/CompPageStudioDesign_state0_elIconaFotocameta835478_835478.png';
import img_state1_elIconaInstagram572998 from './images/CompPageStudioDesign_state0_elIconaInstagram554514_554514.png';
import img_state1_elIconaWhatsapp1016741 from './images/CompPageStudioDesign_state0_elIconaWhatsapp237991_237991.png';
import img_state1_elIconaWhatsappCopy from './images/CompPageStudioDesign_state0_elIconaWhatsapp237991_237991.png';
import img_state1_elIconaInstagramCopy from './images/CompPageStudioDesign_state0_elIconaInstagram554514_554514.png';
import img_state1_elIconaFotocametaCopy from './images/CompPageStudioDesign_state0_elIconaFotocameta835478_835478.png';
import btn_icon_434574 from './images/btn_icon_434574.png';
import img_state2_elIconaFotocameta972705 from './images/CompPageStudioDesign_state0_elIconaFotocameta835478_835478.png';
import img_state2_elIconaInstagram572998 from './images/CompPageStudioDesign_state0_elIconaInstagram554514_554514.png';
import img_state2_elIconaWhatsapp1016741 from './images/CompPageStudioDesign_state0_elIconaWhatsapp237991_237991.png';
import img_state2_elIconaStudioDesignCopy from './images/CompPageStudioDesign_state0_elIconaStudioDesign800240_800240.png';
import img_state3_elIconaInstagram572998 from './images/CompPageStudioDesign_state0_elIconaInstagram554514_554514.png';

export default class ComponentMenu extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 5) --- 
  
  onClick_state0_elIconaMenu625285 = async () => {
    this.setState({visualStateIndexOverride: 1})
  
    // Go to screen 'menu 3'
    this.props.appActions.goToScreen('menu3', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elIconaStudioDesign900480 = async () => {
    this.setState({visualStateIndexOverride: 2})
  
    // Go to screen 'Screen studio design'
    this.props.appActions.goToScreen('screenStudioDesign', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elIconaFotocameta972705 = async () => {
    // Go to screen 'Screen fotografia'
    this.props.appActions.goToScreen('screenFotografia', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elIconaInstagram572998 = async () => {
  
    window.location = 'https://www.instagram.com/giusepperotondo/';
  
  }
  
  
  onClick_state0_elIconaWhatsapp1016741 = async () => {
    window.location = 'https://wa.me/393313363537';
  
  
  }
  
  
  renderState0() {
    const style_state0_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
     };
    
    const style_state0_elIconaMenu625285 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_625285+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '78.748%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elIconaStudioDesign900480 = {
      backgroundImage: 'url('+img_state0_elIconaStudioDesign900480+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elIconaFotocameta972705 = {
      backgroundImage: 'url('+img_state0_elIconaFotocameta972705+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elIconaInstagram572998 = {
      backgroundImage: 'url('+img_state0_elIconaInstagram572998+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elIconaWhatsapp1016741 = {
      backgroundImage: 'url('+img_state0_elIconaWhatsapp1016741+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elTextBlockCopy383977 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state0_elTextBlockCopy383977_inner = {
      textAlign: 'center',
     };
    const style_state0_elTextBlockCopy2947760 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state0_elTextBlockCopy2947760_inner = {
      textAlign: 'center',
     };
    const style_state0_elTextBlockCopy3752199 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state0_elTextBlockCopy3752199_inner = {
      textAlign: 'center',
     };
    const style_state0_elTextBlock468990 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state0_elTextBlock468990_inner = {
      textAlign: 'center',
     };
    const style_state0_elTextBlockCopy4985618 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state0_elTextBlockCopy4985618_inner = {
      textAlign: 'center',
     };
    const style_state0_elTextBlockCopy59535 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state0_elTextBlockCopy59535_inner = {
      textAlign: 'center',
     };
    
    return (
      <div className="ComponentMenu">
        <div className="background">
          <div className="containerMinHeight state0_elBackground" style={style_state0_elBackground_outer}>
            <div style={style_state0_elBackground} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state0_elIconaMenu625285" style={style_state0_elIconaMenu625285} onClick={this.onClick_state0_elIconaMenu625285}  />
          <div className="state0_elIconaStudioDesign900480" style={style_state0_elIconaStudioDesign900480} onClick={this.onClick_state0_elIconaStudioDesign900480}  />
          <div className="state0_elIconaFotocameta972705" style={style_state0_elIconaFotocameta972705} onClick={this.onClick_state0_elIconaFotocameta972705}  />
          <div className="state0_elIconaInstagram572998" style={style_state0_elIconaInstagram572998} onClick={this.onClick_state0_elIconaInstagram572998}  />
          <div className="state0_elIconaWhatsapp1016741" style={style_state0_elIconaWhatsapp1016741} onClick={this.onClick_state0_elIconaWhatsapp1016741}  />
          <div className="systemFontRegular  state0_elTextBlockCopy383977" style={style_state0_elTextBlockCopy383977}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy_383977}</div>
          </div>
          <div className="systemFontRegular  state0_elTextBlockCopy2947760" style={style_state0_elTextBlockCopy2947760}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy2_947760}</div>
          </div>
          <div className="systemFontRegular  state0_elTextBlockCopy3752199" style={style_state0_elTextBlockCopy3752199}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy3_752199}</div>
          </div>
          <div className="systemFontRegular  state0_elTextBlock468990" style={style_state0_elTextBlock468990}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblock_468990}</div>
          </div>
          <div className="systemFontRegular  state0_elTextBlockCopy4985618" style={style_state0_elTextBlockCopy4985618}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy4_985618}</div>
          </div>
          <div className="systemFontRegular  state0_elTextBlockCopy59535" style={style_state0_elTextBlockCopy59535}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy5_9535}</div>
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 5) --- 
  
  onClick_state1_elRect = async () => {
  
  }
  
  
  onClick_state1_elIconaMenu625285 = async () => {
    this.setState({visualStateIndexOverride: 1})
  
    // Go to screen 'menu 3'
    this.props.appActions.goToScreen('menu3', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state1_elIconaStudioDesign900480 = async () => {
    this.setState({visualStateIndexOverride: 2})
  
    // Go to screen 'Screen studio design'
    this.props.appActions.goToScreen('screenStudioDesign', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state1_elIconaFotocameta972705 = async () => {
    // Go to screen 'Screen fotografia'
    this.props.appActions.goToScreen('screenFotografia', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state1_elIconaInstagram572998 = async () => {
  
    window.location = 'https://www.instagram.com/giusepperotondo/';
  
  }
  
  
  onClick_state1_elIconaWhatsapp1016741 = async () => {
    window.location = 'https://wa.me/393313363537';
  
  
  }
  
  
  onClick_state1_elIconaWhatsappCopy = async () => {
    window.location = 'https://wa.me/393313363537';
  
  
  }
  
  
  onClick_state1_elIconaInstagramCopy = async () => {
  
    window.location = 'https://www.instagram.com/giusepperotondo/';
  
  }
  
  
  onClick_state1_elIconaFotocametaCopy = async () => {
    window.open('https://webapp.giusepperotondo.com/lychee/public/gallery#16265429717162', '_blank');
  
  }
  
  
  onClick_state1_elIconaMenuCopy = async () => {
  
  }
  
  
  renderState1() {
    
    const style_state1_elRect = {
      background: 'rgba(254, 255, 255, 1.000)',
      boxShadow: '0.0px 0.9px 2px rgba(0, 0, 0, 0.3000)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconaMenu625285 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_625285+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '78.748%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elIconaStudioDesign900480 = {
      backgroundImage: 'url('+img_state1_elIconaStudioDesign900480+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elIconaFotocameta972705 = {
      backgroundImage: 'url('+img_state1_elIconaFotocameta972705+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elIconaInstagram572998 = {
      backgroundImage: 'url('+img_state1_elIconaInstagram572998+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elIconaWhatsapp1016741 = {
      backgroundImage: 'url('+img_state1_elIconaWhatsapp1016741+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elTextBlockCopy383977 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state1_elTextBlockCopy383977_inner = {
      textAlign: 'center',
     };
    const style_state1_elTextBlockCopy2947760 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state1_elTextBlockCopy2947760_inner = {
      textAlign: 'center',
     };
    const style_state1_elTextBlockCopy3752199 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state1_elTextBlockCopy3752199_inner = {
      textAlign: 'center',
     };
    const style_state1_elTextBlock468990 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state1_elTextBlock468990_inner = {
      textAlign: 'center',
     };
    const style_state1_elTextBlockCopy4985618 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state1_elTextBlockCopy4985618_inner = {
      textAlign: 'center',
     };
    const style_state1_elTextBlockCopy59535 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state1_elTextBlockCopy59535_inner = {
      textAlign: 'center',
     };
    const style_state1_elTextBlockCopy6 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state1_elTextBlockCopy6_inner = {
      textAlign: 'center',
     };
    const style_state1_elTextBlockCopy7 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state1_elTextBlockCopy7_inner = {
      textAlign: 'center',
     };
    const style_state1_elTextBlockCopy8 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state1_elTextBlockCopy8_inner = {
      textAlign: 'center',
     };
    const style_state1_elTextBlockCopy9 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state1_elTextBlockCopy9_inner = {
      textAlign: 'center',
     };
    const style_state1_elTextBlockCopy11 = {
      fontSize: 7.0,
      color: '#ff7473',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state1_elTextBlockCopy11_inner = {
      textAlign: 'center',
     };
    const style_state1_elIconaWhatsappCopy = {
      backgroundImage: 'url('+img_state1_elIconaWhatsappCopy+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elIconaInstagramCopy = {
      backgroundImage: 'url('+img_state1_elIconaInstagramCopy+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elIconaFotocametaCopy = {
      backgroundImage: 'url('+img_state1_elIconaFotocametaCopy+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconaMenuCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_434574+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '78.748%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ComponentMenu">
        <div className="foreground">
          <div className="state1_elRect" style={style_state1_elRect} onClick={this.onClick_state1_elRect}  />
          <button className="actionFont state1_elIconaMenu625285" style={style_state1_elIconaMenu625285} onClick={this.onClick_state1_elIconaMenu625285}  />
          <div className="state1_elIconaStudioDesign900480" style={style_state1_elIconaStudioDesign900480} onClick={this.onClick_state1_elIconaStudioDesign900480}  />
          <div className="state1_elIconaFotocameta972705" style={style_state1_elIconaFotocameta972705} onClick={this.onClick_state1_elIconaFotocameta972705}  />
          <div className="state1_elIconaInstagram572998" style={style_state1_elIconaInstagram572998} onClick={this.onClick_state1_elIconaInstagram572998}  />
          <div className="state1_elIconaWhatsapp1016741" style={style_state1_elIconaWhatsapp1016741} onClick={this.onClick_state1_elIconaWhatsapp1016741}  />
          <div className="systemFontRegular  state1_elTextBlockCopy383977" style={style_state1_elTextBlockCopy383977}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy_383977}</div>
          </div>
          <div className="systemFontRegular  state1_elTextBlockCopy2947760" style={style_state1_elTextBlockCopy2947760}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy2_947760}</div>
          </div>
          <div className="systemFontRegular  state1_elTextBlockCopy3752199" style={style_state1_elTextBlockCopy3752199}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy3_752199}</div>
          </div>
          <div className="systemFontRegular  state1_elTextBlock468990" style={style_state1_elTextBlock468990}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblock_468990}</div>
          </div>
          <div className="systemFontRegular  state1_elTextBlockCopy4985618" style={style_state1_elTextBlockCopy4985618}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy4_985618}</div>
          </div>
          <div className="systemFontRegular  state1_elTextBlockCopy59535" style={style_state1_elTextBlockCopy59535}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy5_9535}</div>
          </div>
          <div className="systemFontRegular  state1_elTextBlockCopy6" style={style_state1_elTextBlockCopy6}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy6_380620}</div>
          </div>
          <div className="systemFontRegular  state1_elTextBlockCopy7" style={style_state1_elTextBlockCopy7}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy7_374016}</div>
          </div>
          <div className="systemFontRegular  state1_elTextBlockCopy8" style={style_state1_elTextBlockCopy8}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy8_1025143}</div>
          </div>
          <div className="systemFontRegular  state1_elTextBlockCopy9" style={style_state1_elTextBlockCopy9}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy9_413878}</div>
          </div>
          <div className="systemFontRegular  state1_elTextBlockCopy11" style={style_state1_elTextBlockCopy11}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy11_682707}</div>
          </div>
          <div className="state1_elIconaWhatsappCopy" style={style_state1_elIconaWhatsappCopy} onClick={this.onClick_state1_elIconaWhatsappCopy}  />
          <div className="state1_elIconaInstagramCopy" style={style_state1_elIconaInstagramCopy} onClick={this.onClick_state1_elIconaInstagramCopy}  />
          <div className="state1_elIconaFotocametaCopy" style={style_state1_elIconaFotocametaCopy} onClick={this.onClick_state1_elIconaFotocametaCopy}  />
          <button className="actionFont state1_elIconaMenuCopy" style={style_state1_elIconaMenuCopy} onClick={this.onClick_state1_elIconaMenuCopy}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 5) --- 
  
  onClick_state2_elRectCopy = async () => {
  
  }
  
  
  onClick_state2_elIconaMenu625285 = async () => {
    this.setState({visualStateIndexOverride: 1})
  
    // Go to screen 'menu 3'
    this.props.appActions.goToScreen('menu3', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state2_elIconaFotocameta972705 = async () => {
    // Go to screen 'Screen fotografia'
    this.props.appActions.goToScreen('screenFotografia', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state2_elIconaInstagram572998 = async () => {
  
    window.location = 'https://www.instagram.com/giusepperotondo/';
  
  }
  
  
  onClick_state2_elIconaWhatsapp1016741 = async () => {
    window.location = 'https://wa.me/393313363537';
  
  
  }
  
  
  onClick_state2_elIconaStudioDesignCopy = async () => {
    this.setState({visualStateIndexOverride: 1})
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  renderState2() {
    
    const style_state2_elRectCopy = {
      background: 'rgba(254, 255, 255, 1.000)',
      boxShadow: '0.0px 0.9px 2px rgba(0, 0, 0, 0.3000)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state2_elIconaMenu625285 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_625285+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '78.748%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state2_elIconaFotocameta972705 = {
      backgroundImage: 'url('+img_state2_elIconaFotocameta972705+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state2_elIconaInstagram572998 = {
      backgroundImage: 'url('+img_state2_elIconaInstagram572998+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state2_elIconaWhatsapp1016741 = {
      backgroundImage: 'url('+img_state2_elIconaWhatsapp1016741+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state2_elTextBlockCopy383977 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state2_elTextBlockCopy383977_inner = {
      textAlign: 'center',
     };
    const style_state2_elTextBlockCopy2947760 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state2_elTextBlockCopy2947760_inner = {
      textAlign: 'center',
     };
    const style_state2_elTextBlockCopy3752199 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state2_elTextBlockCopy3752199_inner = {
      textAlign: 'center',
     };
    const style_state2_elTextBlock468990 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state2_elTextBlock468990_inner = {
      textAlign: 'center',
     };
    const style_state2_elTextBlockCopy4985618 = {
      fontSize: 7.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state2_elTextBlockCopy4985618_inner = {
      textAlign: 'center',
     };
    const style_state2_elTextBlockCopy10 = {
      fontSize: 7.0,
      color: '#ff7473',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state2_elTextBlockCopy10_inner = {
      textAlign: 'center',
     };
    const style_state2_elIconaStudioDesignCopy = {
      backgroundImage: 'url('+img_state2_elIconaStudioDesignCopy+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ComponentMenu">
        <div className="foreground">
          <div className="state2_elRectCopy" style={style_state2_elRectCopy} onClick={this.onClick_state2_elRectCopy}  />
          <button className="actionFont state2_elIconaMenu625285" style={style_state2_elIconaMenu625285} onClick={this.onClick_state2_elIconaMenu625285}  />
          <div className="state2_elIconaFotocameta972705" style={style_state2_elIconaFotocameta972705} onClick={this.onClick_state2_elIconaFotocameta972705}  />
          <div className="state2_elIconaInstagram572998" style={style_state2_elIconaInstagram572998} onClick={this.onClick_state2_elIconaInstagram572998}  />
          <div className="state2_elIconaWhatsapp1016741" style={style_state2_elIconaWhatsapp1016741} onClick={this.onClick_state2_elIconaWhatsapp1016741}  />
          <div className="systemFontRegular  state2_elTextBlockCopy383977" style={style_state2_elTextBlockCopy383977}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy_383977}</div>
          </div>
          <div className="systemFontRegular  state2_elTextBlockCopy2947760" style={style_state2_elTextBlockCopy2947760}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy2_947760}</div>
          </div>
          <div className="systemFontRegular  state2_elTextBlockCopy3752199" style={style_state2_elTextBlockCopy3752199}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy3_752199}</div>
          </div>
          <div className="systemFontRegular  state2_elTextBlock468990" style={style_state2_elTextBlock468990}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblock_468990}</div>
          </div>
          <div className="systemFontRegular  state2_elTextBlockCopy4985618" style={style_state2_elTextBlockCopy4985618}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy4_985618}</div>
          </div>
          <div className="systemFontRegular  state2_elTextBlockCopy10" style={style_state2_elTextBlockCopy10}>
            <div className="bottomAlignedContent">{this.props.locStrings.componentmenu_textblockcopy10_845069}</div>
          </div>
          <div className="state2_elIconaStudioDesignCopy" style={style_state2_elIconaStudioDesignCopy} onClick={this.onClick_state2_elIconaStudioDesignCopy}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 3 (4 of 5) --- 
  
  onClick_state3_elIconaInstagram572998 = async () => {
  
    window.location = 'https://www.instagram.com/giusepperotondo/';
  
  }
  
  
  renderState3() {
    const style_state3_elIconaInstagram572998 = {
      backgroundImage: 'url('+img_state3_elIconaInstagram572998+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ComponentMenu">
        <div className="foreground">
          <div className="state3_elIconaInstagram572998" style={style_state3_elIconaInstagram572998} onClick={this.onClick_state3_elIconaInstagram572998}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 4 (5 of 5) --- 
  
  renderState4() {
    
    return (
      <div className="ComponentMenu">
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
      case 4:
        return this.renderState4();
    }
  }
  
}
