// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';
import datasheet_studioDesign_row0_profilepic from './images/datasheet_studioDesign_row0_profilepic.png';
import datasheet_studioDesign_row1_profilepic from './images/datasheet_studioDesign_row1_profilepic.png';
import datasheet_studioDesign_row2_profilepic from './images/datasheet_studioDesign_row2_profilepic.png';
import datasheet_studioDesign_row3_profilepic from './images/datasheet_studioDesign_row3_profilepic.png';
import datasheet_studioDesign_row4_profilepic from './images/datasheet_studioDesign_row4_profilepic.png';
import datasheet_studioDesign_row5_profilepic from './images/datasheet_studioDesign_row5_profilepic.png';
import datasheet_studioDesign_row6_profilepic from './images/datasheet_studioDesign_row6_profilepic.png';
import datasheet_studioDesign_row7_profilepic from './images/datasheet_studioDesign_row7_profilepic.png';
import datasheet_studioDesign_row8_profilepic from './images/datasheet_studioDesign_row8_profilepic.png';
import datasheet_studioDesign_row9_profilepic from './images/datasheet_studioDesign_row9_profilepic.png';
import datasheet_studioDesign_row10_profilepic from './images/datasheet_studioDesign_row10_profilepic.png';
import datasheet_studioDesign_row11_profilepic from './images/datasheet_studioDesign_row11_profilepic.png';
import datasheet_studioDesign_row12_profilepic from './images/datasheet_studioDesign_row12_profilepic.png';
import datasheet_studioDesign_row13_profilepic from './images/datasheet_studioDesign_row13_profilepic.png';
import datasheet_studioDesign_row14_profilepic from './images/datasheet_studioDesign_row14_profilepic.png';

export default class DataSheet_studioDesign extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row0_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row1_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row2_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row3_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row4_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row5_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row6_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row7_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row8_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row9_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row10_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row11_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row12_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row13_profilepic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['profilepic'] = datasheet_studioDesign_row14_profilepic;
    item.key = key++;
  }

}
