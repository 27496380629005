// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';
import datasheet_fotografia_row0_fashionpic from './images/datasheet_fotografia_row0_fashionpic.png';
import datasheet_fotografia_row1_fashionpic from './images/datasheet_fotografia_row1_fashionpic.png';
import datasheet_fotografia_row2_fashionpic from './images/datasheet_fotografia_row2_fashionpic.png';
import datasheet_fotografia_row3_fashionpic from './images/datasheet_fotografia_row3_fashionpic.png';
import datasheet_fotografia_row4_fashionpic from './images/datasheet_fotografia_row4_fashionpic.png';
import datasheet_fotografia_row5_fashionpic from './images/datasheet_fotografia_row5_fashionpic.png';
import datasheet_fotografia_row6_fashionpic from './images/datasheet_fotografia_row6_fashionpic.png';
import datasheet_fotografia_row7_fashionpic from './images/datasheet_fotografia_row7_fashionpic.png';
import datasheet_fotografia_row8_fashionpic from './images/datasheet_fotografia_row8_fashionpic.png';
import datasheet_fotografia_row9_fashionpic from './images/datasheet_fotografia_row9_fashionpic.png';
import datasheet_fotografia_row10_fashionpic from './images/datasheet_fotografia_row10_fashionpic.png';
import datasheet_fotografia_row11_fashionpic from './images/datasheet_fotografia_row11_fashionpic.png';
import datasheet_fotografia_row12_fashionpic from './images/datasheet_fotografia_row12_fashionpic.png';
import datasheet_fotografia_row13_fashionpic from './images/datasheet_fotografia_row13_fashionpic.png';
import datasheet_fotografia_row14_fashionpic from './images/datasheet_fotografia_row14_fashionpic.png';
import datasheet_fotografia_row15_fashionpic from './images/datasheet_fotografia_row15_fashionpic.png';
import datasheet_fotografia_row16_fashionpic from './images/datasheet_fotografia_row16_fashionpic.png';
import datasheet_fotografia_row17_fashionpic from './images/datasheet_fotografia_row17_fashionpic.png';
import datasheet_fotografia_row18_fashionpic from './images/datasheet_fotografia_row18_fashionpic.png';
import datasheet_fotografia_row19_fashionpic from './images/datasheet_fotografia_row19_fashionpic.png';
import datasheet_fotografia_row20_fashionpic from './images/datasheet_fotografia_row20_fashionpic.png';
import datasheet_fotografia_row21_fashionpic from './images/datasheet_fotografia_row21_fashionpic.png';
import datasheet_fotografia_row22_fashionpic from './images/datasheet_fotografia_row22_fashionpic.png';
import datasheet_fotografia_row23_fashionpic from './images/datasheet_fotografia_row23_fashionpic.png';
import datasheet_fotografia_row24_fashionpic from './images/datasheet_fotografia_row24_fashionpic.png';
import datasheet_fotografia_row25_fashionpic from './images/datasheet_fotografia_row25_fashionpic.png';
import datasheet_fotografia_row26_fashionpic from './images/datasheet_fotografia_row26_fashionpic.png';
import datasheet_fotografia_row27_fashionpic from './images/datasheet_fotografia_row27_fashionpic.png';
import datasheet_fotografia_row28_fashionpic from './images/datasheet_fotografia_row28_fashionpic.png';

export default class DataSheet_fotografia extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row0_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row1_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row2_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row3_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row4_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row5_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row6_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row7_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row8_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row9_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row10_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row11_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row12_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row13_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row14_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row15_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row16_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row17_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row18_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row19_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row20_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row21_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row22_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row23_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row24_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row25_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row26_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row27_fashionpic;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fashionpic'] = datasheet_fotografia_row28_fashionpic;
    item.key = key++;
  }

}
