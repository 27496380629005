import React, { Component } from 'react';
import './App.css';
import img_elProgettoSenzaTitolo19 from './images/InfoScreen_elProgettoSenzaTitolo19_188779.png';
import img_elImage from './images/Menu3Screen_elImage_327063.png';
import img_elImageCopy from './images/Menu3Screen_elImageCopy_637103.png';
import img_elImageCopy2 from './images/Menu3Screen_elImageCopy2_650202.png';
import img_elImageCopy3 from './images/Menu3Screen_elImageCopy3_543178.png';
import CompPageMenu from './CompPageMenu';
import img_elRightarrow from './images/InfoScreen_elRightarrow_395193.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Menu3Screen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elImageCopy = async () => {
    window.location = 'https://wa.me/393313363537';
  
  }
  
  
  onClick_elImageCopy2 = async () => {
    window.location = 'https://www.instagram.com/giusepperotondo/';
  
  }
  
  
  onClick_elImageCopy3 = async () => {
  
  }
  
  
  onClick_elTextBlock2 = async () => {
    // Go to screen 'info'
    this.props.appActions.goToScreen('info', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButtonCopy3 = async () => {
    // Go to screen 'Start 5'
    this.props.appActions.goToScreen('start5', { transitionId: 'slideIn_right' });
  
  }
  
  
  onClick_elButtonCopy = async () => {
    window.open('https://wa.me/393313363537', '_blank');
  
  }
  
  
  onClick_elButton = async () => {
    window.open('https://webapp.giusepperotondo.com/lychee/public/gallery', '_blank');
  
  }
  
  
  onClick_elButtonCopy4 = async () => {
    window.open('https://www.giusepperotondo.com', '_blank');
  
  }
  
  
  onClick_elButtonCopy2 = async () => {
    window.open('https://www.design.giusepperotondo.com', '_blank');
  
  }
  
  
  onClick_elRightarrow = async () => {
    // Go to screen 'Start 2'
    this.props.appActions.goToScreen('start2', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    const style_elProgettoSenzaTitolo19 = {
      backgroundImage: 'url('+img_elProgettoSenzaTitolo19+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elImage = {
      height: 'auto',
     };
    const style_elImageCopy = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elImageCopy2 = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elImageCopy3 = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextBlock2 = {
      fontSize: 11.0,
      color: '#797979',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonCopy3 = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ff7e78',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonCopy = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#929292',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButton = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#424242',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonCopy4 = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#424242',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonCopy2 = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#929292',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elRightarrow = {
      backgroundImage: 'url('+img_elRightarrow+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen Menu3Screen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
          
          <div className="elProgettoSenzaTitolo19" style={style_elProgettoSenzaTitolo19} />
          <img className="elImage" style={style_elImage} src={img_elImage} alt=""  />
          <img className="elImageCopy" style={style_elImageCopy} src={img_elImageCopy} alt="" onClick={this.onClick_elImageCopy}  />
          <img className="elImageCopy2" style={style_elImageCopy2} src={img_elImageCopy2} alt="" onClick={this.onClick_elImageCopy2}  />
          <img className="elImageCopy3" style={style_elImageCopy3} src={img_elImageCopy3} alt="" onClick={this.onClick_elImageCopy3}  />
          <div className="hasNestedComps elComponentPageMenu">
            <CompPageMenu ref={(el)=> this._elComponentPageMenu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="systemFontRegular  elTextBlock2" style={style_elTextBlock2} onClick={this.onClick_elTextBlock2} >
            <div>{this.props.locStrings.menu3_textblock2_63685}</div>
          </div>
          <Button className="actionFont elButtonCopy3" style={style_elButtonCopy3} onClick={this.onClick_elButtonCopy3} >
            {this.props.locStrings.menu3_buttoncopy3_526090}
          </Button>
          <Button className="actionFont elButtonCopy" style={style_elButtonCopy} onClick={this.onClick_elButtonCopy} >
            {this.props.locStrings.menu3_buttoncopy_757570}
          </Button>
          <Button className="actionFont elButton" style={style_elButton} onClick={this.onClick_elButton} >
            {this.props.locStrings.menu3_button_864811}
          </Button>
          <Button className="actionFont elButtonCopy4" style={style_elButtonCopy4} onClick={this.onClick_elButtonCopy4} >
            {this.props.locStrings.menu3_buttoncopy4_795681}
          </Button>
          <Button className="actionFont elButtonCopy2" style={style_elButtonCopy2} onClick={this.onClick_elButtonCopy2} >
            {this.props.locStrings.menu3_buttoncopy2_483331}
          </Button>
          <div className="elRightarrow" style={style_elRightarrow} onClick={this.onClick_elRightarrow}  />
        </div>
        
      </div>
    )
  }
  
}
