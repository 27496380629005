import React, { Component } from 'react';
import './App.css';
import btn_icon_987833 from './images/btn_icon_987833.png';
import btn_icon_388445 from './images/btn_icon_388445.png';
import btn_icon_110373 from './images/btn_icon_110373.png';
import btn_icon_349922 from './images/btn_icon_349922.png';

export default class Start5Screen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elIconButton = async () => {
    // Go to screen 'Start 2'
    this.props.appActions.goToScreen('start2', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elIconButtonCopy2 = async () => {
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  onClick_elIconButtonCopy = async () => {
    // Go to screen 'prompt android'
    this.props.appActions.goToScreen('promptAndroid', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elIconButton2 = async () => {
    // Go to screen 'prompt ios'
    this.props.appActions.goToScreen('promptIos', { transitionId: 'slideIn_right' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    
    const style_elRect = {
      background: 'rgba(253, 125, 120, 1.000)',
     };
    
    const style_elRect2 = {
      background: 'rgba(239, 241, 242, 1.000)',
     };
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_987833+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '76.760%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elIconButtonCopy2 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_388445+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '76.760%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextBlockCopy = {
      fontSize: 13.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_110373+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '78.111%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextBlock = {
      fontSize: 13.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elIconButton2 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_349922+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '78.111%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen Start5Screen" style={baseStyle}>
        <div className="background">
          <div className="elRect" style={style_elRect} />
          <div className="elRect2" style={style_elRect2} />
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
            <button className="actionFont elIconButtonCopy2" style={style_elIconButtonCopy2} onClick={this.onClick_elIconButtonCopy2}  />
            <div className="systemFontRegular  elTextBlockCopy" style={style_elTextBlockCopy}>
              <div>{this.props.locStrings.start5_textblockcopy_188384}</div>
            </div>
            <button className="actionFont elIconButtonCopy" style={style_elIconButtonCopy} onClick={this.onClick_elIconButtonCopy}  />
            <div className="systemFontRegular  elTextBlock" style={style_elTextBlock}>
              <div>{this.props.locStrings.start5_textblock_649606}</div>
            </div>
            <button className="actionFont elIconButton2" style={style_elIconButton2} onClick={this.onClick_elIconButton2}  />
          </div>
        </div>
      </div>
    )
  }
  
}
