import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/PromptIosScreen_elImage_832890.png';
import img_elImage2 from './images/PromptIosScreen_elImage2_811307.png';

export default class PromptIosScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elImage2 = async () => {
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const style_elRect = {
      background: 'rgba(7, 7, 7, 0.778)',
     };
    const style_elImage2 = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen PromptIosScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elImage" style={style_elImage} />
          <div className="containerMinHeight elRect" style={style_elRect} />
          <img className="elImage2" style={style_elImage2} src={img_elImage2} alt="" onClick={this.onClick_elImage2}  />
        </div>
        
      </div>
    )
  }
  
}
