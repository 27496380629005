import React, { Component } from 'react';
import './App.css';
import img_elProgettoSenzaTitolo19 from './images/InfoScreen_elProgettoSenzaTitolo19_188779.png';
import img_elImage from './images/Menu3Screen_elImage_327063.png';
import ComponentMenu from './ComponentMenu';
import img_elImageCopy from './images/Menu3Screen_elImageCopy_637103.png';
import img_elImageCopy2 from './images/Menu3Screen_elImageCopy2_650202.png';
import img_elImageCopy3 from './images/Menu3Screen_elImageCopy3_543178.png';
import btn_icon_121026 from './images/btn_icon_121026.png';
import btn_icon_241381 from './images/btn_icon_241381.png';
import img_elRightarrow from './images/InfoScreen_elRightarrow_395193.png';

export default class MenuScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elImageCopy = async () => {
    window.location = 'https://wa.me/393313363537';
  
  }
  
  
  onClick_elImageCopy2 = async () => {
    window.location = 'https://www.instagram.com/giusepperotondo/';
  
  }
  
  
  onClick_elImageCopy3 = async () => {
  
  }
  
  
  onClick_elIconButton = async () => {
  
  }
  
  
  onClick_elIconButtonCopy = async () => {
    // Go to screen 'Screen studio design'
    this.props.appActions.goToScreen('screenStudioDesign', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elRightarrow = async () => {
    // Go to screen 'Start 2'
    this.props.appActions.goToScreen('start2', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elTextBlock = async () => {
    window.open('https://webapp.giusepperotondo.com/lychee/public/gallery#16265429717162', '_blank');
  
  }
  
  
  onClick_elTextBlockCopy = async () => {
    window.location = 'https://www.instagram.com/giusepperotondo/';
  
  }
  
  
  onClick_elTextBlockCopy2 = async () => {
    window.location = 'https://wa.me/393313363537';
  
  }
  
  
  onClick_elTextBlockCopy3 = async () => {
    window.location = 'https://www.design.giusepperotondo.com';
  
  }
  
  
  onClick_elTextBlockCopy4 = async () => {
    window.location = 'https://www.instagram.com/giusepperotondo/';
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    const style_elProgettoSenzaTitolo19 = {
      backgroundImage: 'url('+img_elProgettoSenzaTitolo19+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elImage = {
      height: 'auto',
     };
    const style_elImageCopy = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elImageCopy2 = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elImageCopy3 = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_121026+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '76.760%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_241381+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '76.760%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elRightarrow = {
      backgroundImage: 'url('+img_elRightarrow+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextBlock = {
      color: '#897570',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    // eslint-disable-next-line no-unused-vars
    const style_elTextBlock_inner = {
      textAlign: 'left',
     };
    
    const style_elRect = {
      background: 'rgba(248, 250, 255, 1.000)',
     };
    const style_elTextBlockCopy = {
      color: '#897570',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    // eslint-disable-next-line no-unused-vars
    const style_elTextBlockCopy_inner = {
      textAlign: 'left',
     };
    const style_elTextBlockCopy2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    // eslint-disable-next-line no-unused-vars
    const style_elTextBlockCopy2_inner = {
      textAlign: 'left',
     };
    
    const style_elRectCopy = {
      background: 'rgba(248, 250, 255, 1.000)',
     };
    
    const style_elRectCopy2 = {
      background: 'rgba(248, 250, 255, 1.000)',
     };
    
    const style_elRectCopy3 = {
      background: 'rgba(248, 250, 255, 1.000)',
     };
    
    const style_elRectCopy4 = {
      background: 'rgba(248, 250, 255, 1.000)',
     };
    const style_elTextBlockCopy3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    // eslint-disable-next-line no-unused-vars
    const style_elTextBlockCopy3_inner = {
      textAlign: 'left',
     };
    
    const style_elRectCopy5 = {
      background: 'rgba(248, 250, 255, 1.000)',
     };
    const style_elTextBlockCopy4 = {
      color: '#897570',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    // eslint-disable-next-line no-unused-vars
    const style_elTextBlockCopy4_inner = {
      textAlign: 'left',
     };
    
    return (
      <div className="AppScreen MenuScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
          
          <div className="elProgettoSenzaTitolo19" style={style_elProgettoSenzaTitolo19} />
          <img className="elImage" style={style_elImage} src={img_elImage} alt=""  />
          <div className="hasNestedComps elComponent">
            <ComponentMenu ref={(el)=> this._elComponent = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <img className="elImageCopy" style={style_elImageCopy} src={img_elImageCopy} alt="" onClick={this.onClick_elImageCopy}  />
          <img className="elImageCopy2" style={style_elImageCopy2} src={img_elImageCopy2} alt="" onClick={this.onClick_elImageCopy2}  />
          <img className="elImageCopy3" style={style_elImageCopy3} src={img_elImageCopy3} alt="" onClick={this.onClick_elImageCopy3}  />
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elIconButton">
            <button className="actionFont" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
          </div>
          
          <div className="elIconButtonCopy">
            <button className="actionFont" style={style_elIconButtonCopy} onClick={this.onClick_elIconButtonCopy}  />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elRightarrow" style={style_elRightarrow} onClick={this.onClick_elRightarrow}  />
            <div className="baseFont elTextBlock" style={style_elTextBlock} onClick={this.onClick_elTextBlock} >
              <div className="bottomAlignedContent">{this.props.locStrings.screen2_textblock_714376}</div>
            </div>
            <div className="elRect" style={style_elRect} />
            <div className="baseFont elTextBlockCopy" style={style_elTextBlockCopy} onClick={this.onClick_elTextBlockCopy} >
              <div className="bottomAlignedContent">{this.props.locStrings.screen2_textblockcopy_150914}</div>
            </div>
            <div className="baseFont elTextBlockCopy2" style={style_elTextBlockCopy2} onClick={this.onClick_elTextBlockCopy2} >
              <div className="bottomAlignedContent">{this.props.locStrings.screen2_textblockcopy2_1020893}</div>
            </div>
            <div className="elRectCopy" style={style_elRectCopy} />
            <div className="elRectCopy2" style={style_elRectCopy2} />
            <div className="elRectCopy3" style={style_elRectCopy3} />
            <div className="elRectCopy4" style={style_elRectCopy4} />
            <div className="baseFont elTextBlockCopy3" style={style_elTextBlockCopy3} onClick={this.onClick_elTextBlockCopy3} >
              <div className="bottomAlignedContent">{this.props.locStrings.screen2_textblockcopy3_69686}</div>
            </div>
            <div className="elRectCopy5" style={style_elRectCopy5} />
            <div className="baseFont elTextBlockCopy4" style={style_elTextBlockCopy4} onClick={this.onClick_elTextBlockCopy4} >
              <div className="bottomAlignedContent">{this.props.locStrings.screen2_textblockcopy4_288412}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
