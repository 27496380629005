// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "screen2_textblock_155042";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen2_textblock2_36678";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen2_textblock_714376";
    item['en'] = "Scarica le tue Foto";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen2_textblockcopy_150914";
    item['en'] = "Seguimi su Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen2_textblockcopy2_1020893";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_textblockcopy2_654770";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_textblockcopy_690111";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_textblock_466504";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_textblockcopy2_556455";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_textblockcopy_849298";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_textblock_864492";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_textblockcopy2_461128";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_textblockcopy_740906";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_textblock_1006829";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen2_textblockcopy3_69686";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen2_textblockcopy4_288412";
    item['en'] = "Fotografia";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen4_textblock_311780";
    item['en'] = "Scarica le tue foto entro 15 giorni dalla consegna della password.\n\nDopo 18 giorni dall’evento, l’album con le foto verrà eliminato.";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen2_textblock2_942108";
    item['en'] = "cosa devi sapere";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_textblock2_518798";
    item['en'] = "cosa devi sapere";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_textblockcopy4_704540";
    item['en'] = "Fotografia";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_textblockcopy3_786290";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_textblockcopy2_789606";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_textblockcopy_65625";
    item['en'] = "Seguimi su Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_textblock_21823";
    item['en'] = "Scarica le tue Foto";
    
    item = {};
    this.items.push(item);
    item['key'] = "button1_iconbutton_14518";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu2_textblockcopy4_791020";
    item['en'] = "Fotografia";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu2_textblockcopy3_795066";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu2_textblockcopy2_520555";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu2_textblockcopy_386790";
    item['en'] = "Seguimi su Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu2_textblock_522602";
    item['en'] = "Scarica le tue Foto";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_textblockcopy4_709476";
    item['en'] = "Fotografia";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_textblockcopy3_28021";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_textblockcopy2_563900";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_textblockcopy_698926";
    item['en'] = "Seguimi su Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_textblock_792112";
    item['en'] = "Scarica le tue Foto";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu2_button_731518";
    item['en'] = "INSTALLA WEB APP";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_button_815694";
    item['en'] = "ENTRA";
    
    item = {};
    this.items.push(item);
    item['key'] = "popup_textblock2_1043755";
    item['en'] = "cosa devi sapere";
    
    item = {};
    this.items.push(item);
    item['key'] = "popupinstall_text_951507";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "popupinstall_textblock_524710";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "popupios_textblock_181671";
    item['en'] = "CLICCA SU ";
    
    item = {};
    this.items.push(item);
    item['key'] = "popupios_textblock_235960";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "popupios_textblock2_574430";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "popupios_text_955374";
    item['en'] = "Clicca su         e ";
    
    item = {};
    this.items.push(item);
    item['key'] = "popupios_iconbutton_149905";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_iconbutton_764319";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_button_208757";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start2_button_107671";
    item['en'] = "ENTRA";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_iconbutton_284250";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_iconbutton_308155";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_textblockcopy4_210067";
    item['en'] = "Fotografia";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_textblockcopy3_597289";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_textblockcopy2_812041";
    item['en'] = "Scrivimi su WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_textblockcopy_90697";
    item['en'] = "Seguimi su Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_textblock_485734";
    item['en'] = "Scarica le tue Foto";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_button_864811";
    item['en'] = "SCARICA LE TUE FOTO";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_buttoncopy_757570";
    item['en'] = "SCRIVIMI SU WHATSAPP";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_buttoncopy2_981039";
    item['en'] = "SCARICA LE TUE FOTO";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_buttoncopy3_571517";
    item['en'] = "SCRIVIMI SU WHATSAPP";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_buttoncopy4_795681";
    item['en'] = "FOTOGRAFIA";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_buttoncopy2_483331";
    item['en'] = "STUDIO DESIGN";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_buttoncopy3_221525";
    item['en'] = "GUARDA SU INSTAGRAM";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_buttoncopy3_526090";
    item['en'] = "installa Web App";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblock_468990";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy_383977";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy2_947760";
    item['en'] = "Fotografia";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy3_752199";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy4_985618";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "popup_iconbutton_635658";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_textblock2_63685";
    item['en'] = "Faq";
    
    item = {};
    this.items.push(item);
    item['key'] = "start3_iconbutton_404879";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start3_button_410404";
    item['en'] = "ENTRA";
    
    item = {};
    this.items.push(item);
    item['key'] = "start4_iconbutton_642254";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start4_button_1029263";
    item['en'] = "ENTRA";
    
    item = {};
    this.items.push(item);
    item['key'] = "start5_iconbutton_987833";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start5_button_497215";
    item['en'] = "ENTRA";
    
    item = {};
    this.items.push(item);
    item['key'] = "start5_iconbutton2_349922";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start5_iconbuttoncopy_110373";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start5_textblock_649606";
    item['en'] = "APPLE";
    
    item = {};
    this.items.push(item);
    item['key'] = "start5_textblockcopy_188384";
    item['en'] = "ANDROID";
    
    item = {};
    this.items.push(item);
    item['key'] = "start5_iconbuttoncopy2_388445";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start4_iconbutton_495860";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "promptios2_button_859027";
    item['en'] = "ENTRA";
    
    item = {};
    this.items.push(item);
    item['key'] = "start6_iconbutton2_559664";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start6_textblock_789974";
    item['en'] = "APPLE";
    
    item = {};
    this.items.push(item);
    item['key'] = "start6_iconbuttoncopy_379845";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start6_textblockcopy_823997";
    item['en'] = "ANDROID";
    
    item = {};
    this.items.push(item);
    item['key'] = "start6_iconbuttoncopy2_819731";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start6_iconbutton_916132";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_iconbutton_625285";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "button1_iconbutton_609337";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_iconbutton_121026";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu3_iconbutton_1022807";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_iconbuttoncopy_241381";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_iconbuttoncopy_609259";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_iconbutton_792451";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_iconbuttoncopy_526515";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_iconbuttoncopy2_770377";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_n2copy_307436";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_iconbuttoncopy_120515";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy5_642339";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy6_342652";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy7_1031546";
    item['en'] = "Download";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy8_295620";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy9_726577";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_iconbuttoncopy_773037";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy5_105775";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy6_26059";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy5_9535";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy6_380620";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy7_374016";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy8_1025143";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy9_413878";
    item['en'] = "Download";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy10_845069";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy11_682707";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_iconamenucopy_434574";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy12_546873";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy13_868046";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy14_181356";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy15_942093";
    item['en'] = "Download";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy16_977291";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_textblockcopy17_1010672";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu_iconamenucopy2_737285";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "component3_button_858077";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "component3_iconbutton_667747";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_iconamenucopy_302201";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_textblockcopy11_555157";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_textblockcopy10_19121";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_textblockcopy9_492004";
    item['en'] = "Download";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_textblockcopy8_153177";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_textblockcopy7_357247";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_textblockcopy6_439610";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_textblockcopy5_625832";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_textblockcopy4_578188";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_textblock_597458";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_textblockcopy3_82848";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_textblockcopy2_247386";
    item['en'] = "Fotografia";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_textblockcopy_201311";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentmenu2_iconamenu_304599";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_iconamenucopy_348830";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_textblockcopy11_795592";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_textblockcopy10_450238";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_textblockcopy9_690403";
    item['en'] = "Download";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_textblockcopy8_134329";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_textblockcopy7_376648";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_textblockcopy6_538674";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_textblockcopy5_925489";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_textblockcopy4_574758";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_textblock_472667";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_textblockcopy3_437945";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_textblockcopy2_564625";
    item['en'] = "Fotografia";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_textblockcopy_902699";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu2_iconamenu_281132";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_iconamenucopy_941130";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_textblockcopy11_182212";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_textblockcopy10_844058";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_textblockcopy9_781371";
    item['en'] = "Download";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_textblockcopy8_442203";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_textblockcopy7_384432";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_textblockcopy6_493243";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_textblockcopy5_969544";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_textblockcopy4_704765";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_textblock_922188";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_textblockcopy3_691208";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_textblockcopy2_263044";
    item['en'] = "Fotografia";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_textblockcopy_818538";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "componentpagemenu3_iconamenu_650698";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_iconamenucopy_586298";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_textblockcopy11_508381";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_textblockcopy10_329476";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_textblockcopy9_961101";
    item['en'] = "Download";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_textblockcopy8_880301";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_textblockcopy7_739900";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_textblockcopy6_128510";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_textblockcopy5_615119";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_textblockcopy4_998893";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_textblock_525733";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_textblockcopy3_790232";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_textblockcopy2_1032365";
    item['en'] = "Fotografia";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_textblockcopy_306763";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagemenu2_iconamenu_154772";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_iconamenucopy_79901";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_textblockcopy11_409894";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_textblockcopy10_728737";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_textblockcopy9_778186";
    item['en'] = "Download";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_textblockcopy8_925438";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_textblockcopy7_609947";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_textblockcopy6_259233";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_textblockcopy5_943574";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_textblockcopy4_642995";
    item['en'] = "WhatsApp";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_textblock_205353";
    item['en'] = "Studio Design";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_textblockcopy3_132088";
    item['en'] = "Instagram";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_textblockcopy2_745600";
    item['en'] = "Fotografia";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_textblockcopy_523159";
    item['en'] = "Menu";
    
    item = {};
    this.items.push(item);
    item['key'] = "comppagestudiodesign2_iconamenu_95177";
    item['en'] = "Icon button";
    
    let storedItems = localStorage.getItem(this.id);
    if (storedItems != null) {
      this.items = JSON.parse(storedItems);
    }
  }

  addItem(item, options) {
    super.addItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  removeItem(item, options) {
    super.removeItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  replaceItemByRowIndex(idx, newItem, options) {
    super.replaceItemByRowIndex(idx, newItem, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
