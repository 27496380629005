import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/PromptIosScreen_elImage_832890.png';
import img_elGroup2 from './images/Start2Screen_elGroup2_713602.png';
import img_elGroup3 from './images/Start2Screen_elGroup3_335396.png';

export default class Start2Screen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elButton = async () => {
    // Go to screen 'menu 3'
    this.props.appActions.goToScreen('menu3', { transitionId: 'slideIn_right' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elGroup2 = {
      backgroundImage: 'url('+img_elGroup2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const style_elButton = {
      display: 'block',
      textTransform: 'uppercase',
      color: '(null)',
      textAlign: 'center',
      backgroundColor: 'rgba(254, 255, 255, 0.2624)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elGroup3 = {
      backgroundImage: 'url('+img_elGroup3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const style_elRect2 = {
      background: 'rgba(239, 241, 242, 1.000)',
     };
    
    return (
      <div className="AppScreen Start2Screen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
          
          <div className="containerMinHeight elImage" style={style_elImage} />
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elGroup2" style={style_elGroup2} />
            <button className="actionFont elButton" style={style_elButton} onClick={this.onClick_elButton} >
              {this.props.locStrings.start2_button_107671}
            </button>
            <div className="elGroup3" style={style_elGroup3} />
            <div className="elRect2" style={style_elRect2} />
          </div>
        </div>
      </div>
    )
  }
  
}
