import React, { Component } from 'react';
import './App.css';
import img_elProgettoSenzaTitolo19 from './images/InfoScreen_elProgettoSenzaTitolo19_188779.png';
import img_elRightarrow from './images/InfoScreen_elRightarrow_395193.png';

export default class InfoScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elRightarrow = async () => {
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elProgettoSenzaTitolo19 = {
      backgroundImage: 'url('+img_elProgettoSenzaTitolo19+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elTextBlock = {
      fontSize: 16.0,
      color: '#212121',
      textAlign: 'left',
     };
    // eslint-disable-next-line no-unused-vars
    const style_elTextBlock_inner = {
      textAlign: 'left',
     };
    const style_elRightarrow = {
      backgroundImage: 'url('+img_elRightarrow+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen InfoScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elProgettoSenzaTitolo19" style={style_elProgettoSenzaTitolo19} />
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="systemFontRegular  elTextBlock" style={style_elTextBlock}>
              <div className="bottomAlignedContent"><div dangerouslySetInnerHTML={{__html: this.props.locStrings.screen4_textblock_311780.replace(/\n/g, '<br>')}}></div></div>
            </div>
            <div className="elRightarrow" style={style_elRightarrow} onClick={this.onClick_elRightarrow}  />
          </div>
        </div>
      </div>
    )
  }
  
}
