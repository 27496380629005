import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/ComponentStudioDesign_elImage_229852.jpg';

export default class ComponentFotografia extends Component {

  // Properties used by this component:
  // fashionpic

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    const style_elImage = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.fashionpic)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    return (
      <div className="ComponentFotografia">
        <div className="foreground">
          <div className="containerMinHeight elImage" style={style_elImage} />
        </div>
      </div>
    )
  }
  
}
